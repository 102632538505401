<template>
    <div
        v-if="value"
        class="true-false-process"
    >
        <v-radio-group
            column
            hide-details
            @change="onReply"
        >
            <v-radio
                :label="$trans('True')"
                color="success"
                value="true"
                class="mb-4"
            ></v-radio>
            <v-radio
                :label="$trans('False')"
                color="error"
                value="false"
            ></v-radio>
        </v-radio-group>
    </div>
</template>

<script>
import questionsTypeBasePropsMixin from './mixins/questionsTypeBasePropsMixin'
export default {
    name: 'AnswersVariantProcess',
    mixins: [questionsTypeBasePropsMixin],
    data: () => ({
        result: {
            reply: null,
            passed: false
        }
    }),
    methods: {
        onReply(value) {
            const correct = this.value.correct
            const result = this.result

            result.reply = value

            result.passed = result.reply === correct

            this.$emit('process', {
                options: this.value,
                score: result.passed ? this.score : 0,
                defaultScore: this.score,
                ...result
            })
        }
    }
}
</script>

<style lang=scss>

</style>
